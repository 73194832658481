import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';

// import dataFounder from '../db/dataFounder';
import Instagram from '../components/Layout/Instagram/Instagram';
import FounderQuotation from '../components/Pages/FounderPage/FounderQuotation/FounderQuotation';
import FounderMainScreen from '../components/Pages/FounderPage/FounderMainScreen/FounderMainScreen';
import FounderSecondSection from '../components/Pages/FounderPage/FounderSecondSection/FounderSecondSection';
import FounderDetails from '../components/Pages/FounderPage/FounderDetails/FounderDetails';
import FounderPhotos from '../components/Pages/FounderPage/FounderPhotos/FounderPhotos';
import FounderSertificates from '../components/Pages/FounderPage/FounderSertificates/FounderSertificates';

const Founder = ({data}) => {
  

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Оксана Кисса',
      title_ru: 'Оксана Кысса',
      title_en: 'Oksana Kyssa',
      to: '/founder/',
    },
  ];

  const { t } = useTranslation();
  return (
    <Layout whitwHeader={'white'}>
      <Seo title={t('Home')} />
      <FounderMainScreen
        dataMain={data.strapiFounderOksana}
        crumbsNav={crumbsNav}
      />
      <FounderSecondSection dataInfo={data.strapiFounderOksana} />
      <FounderPhotos gallery={data.strapiFounderOksana.gallery} />
      <FounderDetails dataDetails={data.strapiFounderOksana.Education} />
      <FounderSertificates diploms={data.strapiFounderOksana.Diploms}/>
      <FounderQuotation dataQuote={data.strapiFounderOksana} />
      <Instagram />
    </Layout>
  );
};

export default Founder;

export const query = graphql`
  query Founder($language: String!) {
    strapiFounderOksana {
      section_title_ua
      section_title_ru
      section_title_en
      title_ua
      title_ru
      title_en
      description_ua
      description_ru
      description_en
      image {
        localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
          }
      }
      Basic_information_ua
      Basic_information_ru
      Basic_information_en
      information {
        title_ua
        title_ru
        title_en
        description_ua
        description_ru
        description_en
    }
    gallery {
      image {
        localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        Education {
          title_ua
          title_ru
          title_en
          Education {
            description_ua
            description_ru
            description_en
            year
          }
        }
        Diploms {
          image {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    layout: FULL_WIDTH
                  )
                }
              }
          }
       }
        Quotation_ua
        Quotation_ru
        Quotation_en
        founder_name_ua
        founder_name_ru
        founder_name_en
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }

`
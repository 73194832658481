import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ArrowLeft from '../../../../svg/arrow_left.svg';
import ArrowRight from '../../../../svg/arrow_right.svg';

const FounderPhotos = ({gallery}) => {

  const slider = React.useRef(null);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <button className="slider-button slider-button__next" onClick={onClick}>
        Next
        <ArrowRight />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="slider-button slider-button__prev" onClick={onClick}>
        Prev
        <ArrowLeft />
      </button>
    );
  }

  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 3, slidesToScroll: 3 },
      },
    ],
  };

  return (
    <section className="founder-photos">
      <div className="container-fluid">
        <Slider ref={slider} {...settings} className="founder-image--slider">
          {gallery.map((img, index) => {
          const image = getImage(img.image.localFile);

            return (
              <div className="founder-slide" key={index}>
                  <GatsbyImage
                    image={image}
                    className="founder-slide--image"
                    alt="founder gallery"
                  />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default FounderPhotos;

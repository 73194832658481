import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';

const FounderSecondSection = ({ dataInfo }) => {
  const langToggle = useLanguage;

  return (
    <section className="founder-second--screen">
      <div className="container-fluid">
        <div className="section-header">
    
            <span className="title">
              {langToggle(
                dataInfo.Basic_information_ua,
                dataInfo.Basic_information_ru,
                dataInfo.Basic_information_en
              )}
            </span>
   
        </div>
        <div className="info-items">
          {dataInfo.information.map((item, index) => {
            return (    
                <div className="info-item" key={index}>
                  <div className="info-item--left">
                    <h2 className="h2">
                      {langToggle(item.title_ua, item.title_ru, item.title_en)}
                    </h2>
                  </div>
                  <div className="info-item--right">
                    <p className="item-text">
                      {langToggle(item.description_ua, item.description_ru, item.description_en)}
                    </p>
                  </div>
                </div>
         
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FounderSecondSection;

import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const LightBox = ({
  img,
  closeModal,
  showLightbox,
}) => {
  const image = getImage(img);

  return (
    <div className={showLightbox ? 'lightbox is-show' : 'lightbox'}>
      <div className="lightbox-overlay" onClick={closeModal} role="presentation"></div>
      <div className="lightbox-wrapper">
        <div className="lightbox-image__wrapper">
          <GatsbyImage
            image={image}
            className="lightBox-image"
            alt="best foto"
          />
        </div>

        <button className="light-box-close" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
};

LightBox.propTypes = {
  img: PropTypes.string,
  closeModal: PropTypes.func,
  showLightbox: PropTypes.bool,
};

export default LightBox;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LightBox from '../../../UI/LightBox/LightBox'

import ArrowLeft from '../../../../svg/arrow_left.svg';
import ArrowRight from '../../../../svg/arrow_right.svg';

const FounderSertificates = ({diploms}) => {
 
  const [showLightbox, setShowLightbox] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(0);

  function handleClick(e, index) {
    e.preventDefault();
    setShowLightbox(!showLightbox);
    setSelectedImage(index);
  }

  function closeModal() {
    setShowLightbox(false);
    setSelectedImage(0);
  }


  const slider = React.useRef(null);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <button className="slider-button slider-button__next" onClick={onClick}>
        Next
        <ArrowRight />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="slider-button slider-button__prev" onClick={onClick}>
        Prev
        <ArrowLeft />
      </button>
    );
  }

  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 3, slidesToScroll: 3 },
      },
    ],
  };

  return (
    <>
      <section className="founder-sertificates">
        <div className="container-fluid">
          <Slider
            ref={slider}
            {...settings}
            className="founder-sertificates--slider"
          >
            {diploms.map((img, index) => {
              const image = getImage(img.image.localFile);
              return (
                <div
                  className="sertificates-slider--slide"
                  key={index}
                  onClick={(e) => handleClick(e, index)}
                  role="presentation"
                >
                  <div className="sertificates-slide-wrapper">
                    <GatsbyImage
                      image={image}
                      className="sertificates-slide--image"
                      alt="doploms"
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
      {showLightbox && (
      <LightBox 
        img={diploms[selectedImage].image.localFile}
        showLightbox={showLightbox}
        closeModal={closeModal}
       />
      )}
    </>
  );
};


export default FounderSertificates;

import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const FounderDetailsContent = ({ contentItems }) => {
  const langToggle = useLanguage;

  return (
    <div className="details-items--wrapper">
      {contentItems.map((itm, index) => {
        const count = index + 1;

        if (itm.year) {
          return (
              <div className="details-item--content" key={index}>
                <div className="item-content--left">
                  <span className="item-content--year">{itm.year}</span>
                </div>
                <div className="item-content--right">
                  <p className="item-content--text">
                    {langToggle(itm.description_ua, itm.description_ru, itm.description_en)}
                  </p>
                </div>
              </div>
          );
        } else {
          return (
              <div className="details-item--content" key={index}>
                <div className="item-content--left">
                  <span className="item-content--year">
                    {count <= 9 ? '0' + count : count}
                  </span>
                </div>
                <div className="item-content--right">
                  <p className="item-content--text">
                    {langToggle(itm.description_ua, itm.description_ru, itm.description_en)}
                  </p>
                </div>
              </div>
          );
        }
      })}
    </div>
  );
};

export default FounderDetailsContent;

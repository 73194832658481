import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import FounderDetailsContent from './FounderDetailsContent/FounderDetailsContent';

const FounderDetails = ({ dataDetails }) => {
  const langToggle = useLanguage;
  return (
    <section className="founder-details--section">
      <div className="container-fluid">
        <div className="details-items">
          {dataDetails.map((item, index) => {
            const count = index + 1;
            return (
              <div className="details-items--item" key={index}>
  
                  <header className="details-item--header">
                    <span className="item-header--count">
                      {count <= 9 ? '0' + count + ' /' : count + ' /'}
                    </span>
                    <span className="item-header--title">
                      {langToggle(item.title_ua, item.title_ru, item.title_en)}{' '}
                    </span>
                  </header>
  
                <FounderDetailsContent contentItems={item.Education} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FounderDetails;

import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-plugin-react-i18next';

const FounderMainScreen = ({ dataMain, crumbsNav }) => {
  
  const langToggle = useLanguage;

  const image = getImage(dataMain.image.localFile);

  return (
    <>
      <section className="founder-mainScreen scroller-top">
        <div className="mainScreen-left">
          <GatsbyImage
            className="mainScreen-left--image"
            image={image}
            alt={langToggle(
              dataMain.section_title_ua,
              dataMain.section_title_ru,
              dataMain.section_title_en
            )}
          />
        </div>
        <div className="mainScren-right">
          <span className="section-title">
            {langToggle(
              dataMain.section_title_ua,
              dataMain.section_title_ru,
              dataMain.section_title_en
            )}
          </span>
          <h1 className="section-description">
            {langToggle(
              dataMain.title_ua,
              dataMain.title_ru,
              dataMain.title_en
            )}
          </h1>
          <p className="section-text">
            {langToggle(dataMain.description_ua, dataMain.description_ru, dataMain.description_en)}
          </p>
        </div>
      </section>
      <div className="container-fluid">
          <ul className="crumbsNav crumbsNav-founder">
            {crumbsNav.map((item, index) => {
              return (
                <li className="crumbsNav-item" key={index}>
                  <Link className="crumbsNav-item__link" to={item.to}>
                    {langToggle(item.title_ua, item.title_ru, item.title_en)}
                  </Link>
                </li>
              );
            })}
          </ul>
      </div>
    </>
  );
};

export default FounderMainScreen;
